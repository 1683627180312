const trimEnd = require('lodash/trimEnd')

/**
 *
 * Configurations variables for our linkResolver
 *
 * @param {array[string]} homePageUIDs
 * when the UID equals any of the ids in homePageUIDs,
 * the page will be generated *not* under the original, but as *index* page
 * For example, a page with UID `home` will be accessible under `http://localhost:3000/`
 * You can add more to make have some flexibility in the CMS, however, make sure you do not have many uids that will become the home page!
 *
 * @param {array[string]} typesNeedUnpublishedPagePreview
 * For these types to be able to preview, even if they have never been published.
 * If you add/remove any types from here, before to update the `pages` option of `gatsby-source-prismic-graphql` in `gatsby-config.js`
 * Please do not add Single Type here as they usually are already published and you cannot create multiple instances for those types.
 *
 */

const homePageUIDs = ['homepage']
const typesNeedUnpublishedPagePreview = ['text_page', 'page']

/**
 *
 * @function parseUID
 *
 * @param {string} uid
 *
 * We allow nested page in our setup.
 *
 * If a page called *subpage* needs to be under *about* page,
 * The editor needs to set the UID/Slug in Prismic to:
 * `about.subpage`
 *
 * Since using `/` is not possible in the field, we use a `.` dot instead.
 *
 */
const parseUID = uid => {
  const path = uid.split('.').join('/')
  if (homePageUIDs.indexOf(path) > -1) {
    return ''
  }
  return path
}

/**
 *
 * @function linkResolver
 * @param {object} doc
 *
 * The *regular* linkResolver you should use to render the page.
 *
 */
const linkResolver = doc => {
  const type = doc.type.toLowerCase()
  const uid = parseUID(doc.uid)
  // These are types for creating pages
  if (type === 'homepage') return '/'
  if (type === 'contact') return '/contact'
  if (type === 'page') return `/${uid}`
  if (type === 'text_page') return `/${uid}`
  if (type === 'site_configuration') return `/`
  if (type === 'company') return `/portfolio#${uid}`
  if (type === 'social_network') return '/contact'
  if (type === 'team_member') return `/team#${uid}`
  // Fallback
  return '/'
}

/**
 *
 * @function linkResolver
 * @param {object} doc
 *
 * The *preview* linkResolver you should use for registerLinkResolver in `gatsby-browser.js`
 * This is also used in `gatsby-config.js` to output proper `match` config
 *
 */
const previewLinkResolver = doc => {
  const result = linkResolver(doc)
  if (typesNeedUnpublishedPagePreview.indexOf(doc.type) > -1) {
    return `/internal-preview${result.replace('#', '/')}`
  }
  return result
}

/**
 *
 * @function getPreviewDirectory
 * @param {string} type
 *
 * This is a helper function used in `gatsby-config.js` to output proper `path` config
 *
 */
const getPreviewDirectory = type => {
  const result = previewLinkResolver({ type, uid: '' })
  return trimEnd(trimEnd(result, '#'), '/')
}

module.exports = {
  homePageUIDs,
  typesNeedUnpublishedPagePreview,
  parseUID,
  linkResolver,
  previewLinkResolver,
  getPreviewDirectory,
}
