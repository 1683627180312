const { registerLinkResolver } = require("gatsby-source-prismic-graphql")
const { previewLinkResolver } = require("./src/prismic/linkResolver")
/**
 *
 * Attention!
 *
 * We register `previewLinkResolver` here for preview setup
 *
 * When you render the page with Prismic Rich Text library,
 * you have to pass the *normal* `linkResolver`
 *
 */
registerLinkResolver(previewLinkResolver)
